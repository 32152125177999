//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "PostPreview",
    props: {
        post: Object
    },
    data() {
        return {
            current_lang: this.$i18n.locale
        }
    },
    methods: {
        getTitle() {
            return (this.current_lang === 'ru') ? this.post.translates[0].title : this.post.translates[1].title;
        },
        getDescription() {
            return (this.current_lang === 'ru') ? this.post.translates[0].description : this.post.translates[1].description;
        },
        goToPost(id) {
            this.$router.push({path: this.localePath('/blog/post/' + id)});
        }
    }
}
