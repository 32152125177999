//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonEdit from "@/components/admin/buttons/ButtonEdit";

export default {
  name: "PostsList",
  components: {ButtonEdit},
  data() {
    return {
      posts: []
    }
  },
  methods: {
    async loadPostToEdit(id) {
      let postForEdit = await this.$PostService.view(id)

      if (postForEdit !== false) {
        this.$nuxt.$emit('edit-post', postForEdit)
      } else {
        this.$nuxt.$toast.info('Не удалось загрузить пост для редактирования')
      }
    },
    async deletePost(id) {
      let isDeleted = this.$PostService.delete(id)

      if (isDeleted) {
        this.$toast.success('Пост успешно удалён')
        this.$fetch()
      } else {
        this.$toast.error('Ошибка удаления поста')
      }
    }
  },
  async fetch() {
    this.posts = await this.$PostService.getAll()
  },
  created() {
    this.$nuxt.$on('post-created', () => {
      this.$fetch()
    })
  }
}
