//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostTranslatableFields from "@/components/admin/posts/PostTranslatableFields";
import Multiselect from 'vue-multiselect'


export default {
  name: "CreatePostForm",
  components: {PostTranslatableFields, Multiselect},
  data() {
    return {
      mode: 'create',
      post_id: null,
      active_tab: 'rus',
      russian: {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 1
      },
      english: {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 2
      },
      games: [],
      selected: [],
    }
  },
  async fetch() {
    this.games = await this.$GameService.getAll()
  },
  methods: {
    clearForm() {
      this.mode = 'create'
      this.post_id = -1
      this.post_id = null
      this.selected = []
      this.russian = {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 1
      }
      this.english = {
        title: null,
        content: null,
        description: null,
        meta_title: null,
        meta_description: null,
        language_id: 2
      }
    },
    refreshCurrentPage() {
      this.$router.go(this.$router.currentRoute)
    },
    async createPost() {
      let isCreated = await this.$PostService.create({
        games: this.selected,
        author_id: 1,
        translates: [
          this.russian, this.english
        ]
      })

      if (isCreated) {
        this.$toast.success('Пост успешно создан')
        this.refreshCurrentPage()
      } else {
        this.$toast.error('Ошибка создания поста')
      }
    },
    async editPost() {
      this.russian.post_id = this.post_id
      this.english.post_id = this.post_id
      let isEdited = await this.$PostService.edit({
        id: this.post_id,
        games: this.selected,
        author_id: 1,
        translates: [
          this.russian, this.english
        ]
      })
      if (isEdited) {
        this.$toast.success('Пост успешно изменён')
        this.clearForm()
        this.$nuxt.$emit('post-created')
      } else {
        this.$toast.error('Не удалось отправить изменения. Возможно, есть пустые поля')
      }

    }
  },
  created() {
    this.$nuxt.$on('change-post-content', (data) => {
      if (data.lang === 'rus') {
        this.russian.content = data.content
      } else {
        this.english.content = data.content
      }
    })

    this.$nuxt.$on('edit-post', (postForEdit => {
      this.post_id = postForEdit.id
      this.selected = postForEdit.games
      this.author_id = postForEdit.author_id
      this.russian = postForEdit.translates[0]
      this.english = postForEdit.translates[1]

      this.mode = 'edit'
    }))
  }
}
