//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";

export default {
  name: "PostFull",
  components: {Breadcrumbs},
  props: {
    id: String
  },
  data() {
    return {
      current_lang: this.$i18n.locale,
      post: null
    }
  },
  methods: {
    isRus() {
      return (this.current_lang === 'ru')
    },
    getId() {
      return this.post.id
    },
    getTitle() {
      if (this.post === null) return ''

      return (this.isRus()) ? this.post.translates[0].title : this.post.translates[1].title;
    },
    getDescription() {
      if (this.post === null) return ''

      return (this.isRus()) ? this.post.translates[0].description : this.post.translates[1].description;
    },
    getContent() {
      if (this.post == null) return ''

      return (this.isRus()) ? this.post.translates[0].content : this.post.translates[1].content;
    }
  },
  async fetch() {
    this.post = await this.$BlogService.getById(this.id)
  },
  head() {
    const currentLangId = (this.current_lang === 'ru') ? 0 : 1;

    return {
      title: this.post ? this.post.translates[currentLangId].meta_title : '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.post ? this.post.translates[currentLangId].meta_description : ''
        },
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
        {hid: 'description', name: 'description', content: ''},
        {name: 'format-detection', content: 'telephone=no'},
        {name: 'yandex-verification', content: '5f68c461d73d1f52'}
      ],
    }
  },
}
