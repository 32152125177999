//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostPreview from "@/components/blog/PostPreview";
import Pagination from "@/components/ui/Pagination.vue";
import {mapGetters, mapActions} from "vuex";
export default {
  name: "Blog",
  components: {Pagination, PostPreview},
  computed: {
    ...mapGetters(['getPage']),
  },
  data() {
    return {
      search: '',
      postsData: [],
      page: 1
    }
  },
  async fetch() {
    this.page = this.$route.query.page || 1;
    this.postsData = await this.$BlogService.getAllByPage(this.page)
  },
  watch: {
    '$route.query.page': async function (newPage, oldPage) {

      if(this.$route.query.page === '1') {
        await this.$router.replace(this.localePath('blog'))
      }

      if (newPage !== oldPage) {
        this.page = newPage || 1;
        this.postsData = await this.$BlogService.getAllByPage(this.page);
      }
    },
    async search() {
      this.page = 1;
      this.postsData = await this.$BlogService.getAllByPage(this.page, this.search)
    }
  },
  mounted() {
    if(this.$route.query.page === '1') {
      this.$router.replace(this.localePath('blog'))
    }
  }
}
