//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileList from "@/components/admin/files/FileList";

export default {
  name: "MediaFilesModal",
  components: {FileList},
  props: {
    isOpen: Boolean,
  },
  data() {
    return {
      modal: null
    }
  },
  methods: {
    closeModal() {
      this.$nuxt.$emit('close-media-files-modal')
    }
  },
  mounted() {
    this.modal = this.$modal(this.$refs.bsModal);
  },
  watch: {
    isOpen: function (newVal, oldVal) {
      if (newVal) {
        this.modal.show()
      } else {
        this.modal.hide()
      }
    }
  }
}
